import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"


const StyledContainer = styled(Container)`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto 20px;
  padding: 40px 0;

    ${breakpoint.medium`
      margin: 50px auto;
    `}
`

const StyledRevolution = styled.section`
	background-color: ${colors.grayLight};
	position: relative;
	border-bottom-width: 0;
	text-align: center;
  
  ${breakpoint.small`
	text-align: left;
  `}

  .heading {
	margin: 0px 30px;
	padding: 80px 0px;

	  ${breakpoint.small`
		padding: 80px 20px;
	  `}

	    
    h2 { 
    	color: ${colors.black};
    	text-align: center;
    	padding-bottom: 40px;
    }

	p {
		margin-bottom: 1rem;
		text-align: center;
	}

	b {
		color: ${colors.purpleDark};
	}

	.content {
		margin: 0 auto;
	 }
	 
		.sub {
			font-style: italic;
			margin-top: 10px;
			line-height: 1.1em;
			font-size: x-small;
			color: ${colors.graySelect};

			${breakpoint.medium`
			  font-size: small;
			`}
		}
	   }
`

const Revolution = () => {
	return (
		<StyledRevolution>
			<StyledContainer>
				<div className="heading">
					<h2 data-aos="fade-up" data-aos-once="false">Visby Medical PCR</h2>
					<div className="content" data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
					<p><b>Game-changing:</b> The first true PCR point-of-care device for STI that’s all-in-one and requires no separate instrument or reader.</p>
					<p>Results available during the patient’s visit help to eliminate the guesswork of empirical medicine and patient callbacks associated with lab send outs.</p>
					<p>With the confidence of a PCR result, clinicians can prescribe the correct treatment and engage in a clear, guiding discussion with the patient.</p>
					</div>
				</div>
			</StyledContainer>
		</StyledRevolution>
	)
}

export default Revolution
